import { useContext, useReducer } from 'react';
import { MailIcon  } from '@primer/octicons-react'
import UseAnimations from 'react-useanimations';
import axios from 'axios';
import './index.scss';

import ThemeContext from "../../context/ThemeContext";
import Man from '../../assets/People.svg';
import { ValidateEmail } from '../Helpers';
import loading from 'react-useanimations/lib/loading'

const Subscribe = () => {
    
    const theme = useContext(ThemeContext);

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        isLoading: false,
        isError: false,
        isSuccess: false,
        email: ''
    });

    const submitHandler = () => {
        if (state.email === '' || !ValidateEmail(state.email)){
            setState({ isError: true });
        } else {
            setState({ 
                isLoading: true,
                isError: false,
                isSuccess: false 
            });
            
            axios.post(theme.paths.postSubscribeEmail, {
                email: state.email,
                site: theme.site
            })
            .then((response) => {
                console.log(response);
                if (response.data.status === 'true'){
                    setState({
                        isLoading: false,
                        isError: false,
                        isSuccess: true
                    });
                } else {
                    setState({
                        isLoading: false,
                        isError: true,
                        isSuccess: false
                    });
                }
            })
            .catch((error) => {
                setState({
                    isLoading: false,
                    isError: true,
                    isSuccess: false
                });
            });
        }
    }

    return (
        <div className="section section-subscribe pt-5 mt-5">
            <svg className="svg-border-angled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="currentColor">
                <polygon points="0,100 100,0 100,100"></polygon>
            </svg>

            <div className="container py-5">
                <div className="card p-sm-4 p-md-5">
                    <div className="card-body">
                        <div className="row no-gutters">
                            
                            <div className="col-12 col-md-8 py-3">
                                <h3>Want more layouts, templates, and UI tools?</h3>
                                <p className="lead ftw-400 text-secondary">Subscribe to get updated when new {theme.site} related products arrive!</p>
                            
                                { state.isSuccess ? 
                                    <div className="py-4">
                                        <p className="lead text-success ftw-400">Yayy! You have been added to our mailing list.</p>
                                    </div>
                                    :
                                    <div className={`input-group input-group-lg mb-3 ${state.isLoading ? 'disabled': ''}`}>
                                        <div className="input-group-prepend">
                                            <span className={`input-group-text ${state.isError ? 'border-danger' : ''} border-right-0 bg-transparent text-secondary` }>
                                                {state.isLoading ?  
                                                    <UseAnimations animation={loading} size={24} className="use-anim-wrap" wrapperStyle={{ margin: '0', position: 'relative' }} />
                                                    :
                                                    <MailIcon size={24} />
                                                }
                                            </span>
                                        </div>
                                        <input type="text" className={`form-control border-left-0 ${state.isError ? 'is-invalid' : ''}`} placeholder="Email address ..." onChange={ (e) => setState({ email: e.target.value })}/>
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary rounded-right ls-8" type="button" onClick={ submitHandler }>Subscribe</button>
                                        </div>
                                        <div className="invalid-feedback">Please provide a valid email address</div>
                                    </div>
                                }


                                <small>
                                    <em className="help-text text-muted">This site is protected by reCAPTCHA and the Screenplify Privacy Policy and Terms of Service apply.</em>
                                </small>   
                            </div>

                            <div className="col-12 col-md-4 img-wrap">
                                <img alt="man-subscribe" className="img-fluid w-75 ml-auto mt-n4 d-none d-md-block" src={ Man } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
 
export default Subscribe;