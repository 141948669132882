
import React, { Component } from 'react';

import './Navbar.scss';
import ThemeContext from '../../context/ThemeContext';
import { ReactComponent as ScreenplifyLogo } from '../../assets/screenplify_logo.svg';
import { ReactComponent as ScreensLogo } from '../../assets/screens-full-inverse-color-logo.svg';

class Navbar extends Component {

	static contextType = ThemeContext;

	state = {
		open: false
	}

	render() {
		
		const { open } = this.state;
		const { links } = this.context.header;

		return (

			<nav id="main-nav" className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
				<div className="container">
					
					<a href="/" className="navbar-brand ls-8 ftw-600">
						{this.context.site === 'Screenplify' ? <ScreenplifyLogo /> : <ScreensLogo />}
					</a>

					<button onClick={ () => this.setState({ open: !open }) } className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					
					<div className={ 'collapse navbar-collapse ' + (open? 'show' : '') } id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							
							{
								links.map((item, index) => (
							
									<li className="nav-item text-center py-3 py-md-0 px-2" key={ index }>
										{ item.type === 'link' && <a href={ item.link } target={ item.target } className="nav-link ls-8 ftw-600 px-3" >{ item.text }</a>}
										{ item.type === 'button' && <a href={ item.link } target={ item.target } className="btn btn-primary ls-8 ftw-600 px-3" >{ item.text }</a>}
									</li>

								))
							}

						</ul>
					</div>

				</div>
			</nav>
		);
	}
}

export default Navbar;