import { useContext, useEffect, useReducer, useRef } from 'react';
import axios from 'axios';

import './index.scss';
import HomeContext from '../../context/HomeContext';
import ThemeContext from '../../context/ThemeContext';
import Card from '../ListingCard';
import { inIframe, UpdateQueryString } from "../Helpers";

const Listing = () => {

    const refListing = useRef();
    
    const theme = useContext(ThemeContext);
    const context = useContext(HomeContext);

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        isLoading: true,
        isError: false,
        listing: []
    });

    const onSelect = () => { }

    useEffect(() => {

        const getListing = () => {

            const _context = JSON.stringify(context); // Remove the Functions
            const params = JSON.parse(_context);
            const queryString = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');

            let URL = theme.paths.getTemplatesListing + '?' + queryString;
                URL = UpdateQueryString(URL, 'site', encodeURIComponent(theme.site.toLowerCase()));
                URL = UpdateQueryString(URL, 'ts', new Date().getTime());
            
            // Update Window QueryString    
            // window.location.search = queryString;
            window.history.replaceState(null, null, "?" + queryString);

            axios
                .get(URL)
                .then((resp) => {
                    if (resp.data.status === 'true') {

                        setState({
                            isLoading: false,
                            isError: false,
                            listing: resp.data.listing
                        });

                    } else {
                        setState({
                            isLoading: false,
                            isError: true,
                            listing: []
                        });
                    }

                    if(inIframe()){
                        try {
                            window.parent.__winResize();
                        } catch (error) {
                            console.log(error)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setState({
                        isLoading: false,
                        isError: true,
                        listing: []
                    });
                });
        }

        getListing();

        return () => { };
    }, [context, theme])


    if (state.isLoading) {
        return (
            <div className="section section-listing py-5">
                <div className="container-fluid text-center py-5" style={{minHeight: '250px'}}>
                    <div className="loading-icon impetus s-32"></div>
                </div>
            </div>
        )
    }

    if (state.isError) {
        return (
            <div className="section section-listing py-5">
                <div className="container-fluid text-center py-5" style={{minHeight: '250px'}}>
                    <h5 className="ls-8 py-4 text-danger">Oops!</h5>
                    <p className="text-secondary ls-8">Something went wrong. Close and try again.</p>
                </div>
            </div>
        )
    }

    if (state.listing.length < 1) {
        return (
            <div className="section section-listing py-5">
                <div className="container-fluid text-center py-5" style={{minHeight: '250px'}}>
                    <h5 className="ls-8 py-4">No results found</h5>
                    <p className="text-secondary ls-8">It seems we can’t find any results based on your search.</p>
                </div>
            </div>
        )
    }

    return (
        <div ref={ refListing } className="section section-listing">
            <div className="container pt-4 pb-5 mb-5">
                <div className="section-header text-center"><hr/></div>
                <div className="row d-flex align-items-center justify-content-center">

                    {state.listing.map((item) => (
                        <Card {...item} key={item.id} onSelect={() => onSelect(item)} />
                    ))}

                </div>
            </div>
        </div>
    );
}
 
export default Listing;