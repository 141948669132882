
import './index.scss';
import { SearchIcon } from '@primer/octicons-react';
import CardImage from './CardImage';
import { inIframe } from '../Helpers';

const Card = (props) => {
	
	/*
	const opts = {
		loop: true,
		parentEl: '#app',
		baseClass: 'card-preview',
		buttons: [ "zoom", "thumbs", "close" ],
		thumbs: { autoStart: true },
		smallBtn: false,
		hash: false,
		toolbar: true,
		idleTime: 0
	}
	*/

	const opts = {
		// parentEl: 'body',
	    baseClass: "quick-view-container",
	    infobar: false,
	    buttons: false,
	    thumbs: false,
	    margin: 0,
	    touch: {
			vertical: false
	    },
	    animationEffect: false,
	    transitionEffect: "slide",
	    transitionDuration: 500,

	    baseTpl: `
			<div class="fancybox-container" role="dialog">
				<div class="quick-view-content">
					<div class="quick-view-carousel">
						<div class="fancybox-stage"></div>
						<div class="fancybox-caption">
							<div class=""fancybox-caption__body"></div>
						</div>
					</div>
					<div class="quick-view-aside"></div>
					<button data-fancybox-close class="quick-view-close">X</button>
				</div>
			</div>
	    `,

		clickContent: () => false,

	    onInit: function(instance) {

	    	// Create Bullets
			var bullets = '<ul class="quick-view-bullets">';

			for (var i = 0; i < instance.group.length; i++) {
				bullets += `<li><a data-index="${i}" href="javascript:;"><span>${(i + 1)}</span></a></li>`;
			}

			bullets += "</ul>";

			window.$(bullets)
				.on('click touchstart', 'a', function(){
					var index = window.$(this).data("index");
					window.$.fancybox.getInstance(function() { this.jumpTo(index); });
				})
				.appendTo(instance.$refs.container.find(".quick-view-carousel"));

			
	      	// Insert Details
			const infoCard =`
	      			<div class="px-4 py-2">
	      				<div class="title">${props.name}</div>
	      				<div class="desc">${props.description}</div>
	      				<div class="info"><div class="info-title">category</div><div class="info-value text-capitalize">${props.category}</div></div>
	      				<div class="info"><div class="info-title">orientation</div><div class="info-value text-capitalize">${props.orientation}</div></div>
	      				<div class="info"><div class="info-title">no of pages</div><div class="info-value">${props.pages}</div></div>
	      				<div class="info"><div class="info-title">size</div><div class="info-value">${props.size}</div></div>
	      				<div class="info"><div class="info-title">resolution</div><div class="info-value">${props.resolution}</div></div>
	      			</div>
				  `;
				  
			// <div class="text-center pt-5">
			// 	<button class="btn btn-light py-3 px-5 btn-select">
			// 		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" class="mr-2"><path fill-rule="evenodd" d="M7.47 10.78a.75.75 0 001.06 0l3.75-3.75a.75.75 0 00-1.06-1.06L8.75 8.44V1.75a.75.75 0 00-1.5 0v6.69L4.78 5.97a.75.75 0 00-1.06 1.06l3.75 3.75zM3.75 13a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5z"></path></svg>
		    //   					Install
		    //   				</button>
			// </div>

			window.$(infoCard)
	      		.on('click touchstart', '.btn-select', (e) => {
	      			e.preventDefault();
	      			props.onSelect();
	      		})
	      		.appendTo(instance.$refs.container.find(".quick-view-aside"))	

	    },

	    beforeShow: function(instance) {
			// Mark current bullet navigation link as active
			instance.$refs.container
				.find(".quick-view-bullets")
				.children()
				.removeClass("active")
				.eq(instance.currIndex)
				.addClass("active");
	    }
	}

	const launchPreview = () => {
		console.log(props.screenshots)
		if (inIframe()){
			opts['parentEl'] = 'body';
			window.parent.$.fancybox.open( props.screenshots, opts, 0 );
		} else {
			window.$.fancybox.open( props.screenshots, opts, 0 );
		}
	}


	return (
		<div className="col-12 col-sm-4 col-md-3">
			<div className="layout-card card border-0 mb-4">
				<div className="layout-card-image">
					<CardImage className="inner landscape" src={props.image} />
					
					<div className="layout-card-overlay">
						{/* <div className="w-100 pt-5 text-center">
							<button className="btn btn-light py-3 px-5" onClick={ props.onSelect }><DownloadIcon size={16} className="mr-2" />Install</button>	
						</div> */}
						<div className="mt-auto w-100 d-flex justify-content-start align-items-center">
							<div className="layout-card-category">{props.category}</div>
							<button className="btn btn-light ml-auto hint--top" aria-label="Details" onClick={ launchPreview }><SearchIcon size={16} /></button>	
						</div>
					</div>
				</div>
				<div className="layout-card-body">
					<div className="card-body py-2 px-3">
						<h5 className="layout-card-title">{props.name}</h5>
						<div className="layout-card-info">
							<div><span>{props.orientation}</span> <span className="ml-auto">{props.size}</span></div>
							<div><span>{props.resolution}</span> <span className="ml-auto">{props.pages} {props.pages > 1 ? 'pages' : 'page'}</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Card;