

import { useEffect, useState } from 'react';
import './index.scss';

const ClockWidget = () => {

    const [time, setTime] = useState(new Date());
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {

        const timer = setInterval(() => {
            setTime(new Date());

        }, 60000);

        return () => clearInterval(timer)
    }, []);



    return (
        <div className="clock-widget">
            <div className="day">{`${days[time.getDay()]}, ${time.getDate()} ${months[time.getMonth()]} ${time.getFullYear()} `}</div>
            <div className="time">{`${time.getHours()}:${time.getMinutes()}`}</div>
        </div>
    );
}
 
export default ClockWidget;