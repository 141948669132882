import { useEffect, useState, createRef } from 'react';

import './index.scss';

const MultiSelect = ({ title, options, onChange }) => {
	const guid = Math.floor(50*Math.random())+""+(new Date).getTime();
	const optionsLength = options.length;
	const [selected, setSelected] = useState([]);
	const [inputRefs, setInputRefs] = useState([]);


	useEffect(() => {
		// add or remove refs
		setInputRefs(inputRefs => (
			Array(optionsLength).fill().map((_, i) => inputRefs[i] || createRef())
		));
	}, [optionsLength]);


	const handleChange = (e, item) => {
		let newSelected = [...selected];

		if(e.target.checked){
			newSelected.push(item);
			setSelected(newSelected);
		} else {
			let index = findIndexByValue(newSelected, 'value', item.value);
			if(index !== -1){
				newSelected.splice(index, 1);
    			setSelected(newSelected);
			}
		}

		onChange(newSelected);
	}


	const handleClear = () => {
		setSelected([]);
		onChange([]);

		// Clear Inputs
		inputRefs.forEach((ele) => ele.current.checked = false);
	}


	return (
		<li className="nav-item dropdown fancy-dropdown multi-select">
			<div className="title">{ title }</div>

			<button className="btn as-is dropdown-toggle btn-outline-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				{ selected.length ? 
						selected.length === options.length ? 
							<span>All</span> 
						: 
							selected.map((item, index) => (
								<span key={index} className="ms-pills">{ item.label }</span>
							))
					:
						<span>Select ...</span> 
				} 
			</button>
			<div className="dropdown-menu p-4" style={{minWidth: '350px'}}>
				<div className="row no-gutters">
					
					{options.map((item, index) => (
						<div className="col-6 " key={index}>
							<div className="custom-control custom-checkbox">
								<input ref={inputRefs[index]} type="checkbox" className="custom-control-input" id={`cd-${guid}-${index}`} onChange={ (e) => handleChange(e, item) }/>
								<label className="custom-control-label" htmlFor={`cd-${guid}-${index}`}>{ item.label }</label>
							</div>
						</div>
					))}

					<div className="col-6 d-flex align-items-center">
						<button className="btn btn-link btn-block border-0 text-muted btn-sm text-uppercase ftw-600 btn-clear" onClick={ handleClear }>Clear</button>
					</div>

				</div>
			</div>

		</li>
	)
}

export default MultiSelect;


// https://stackoverflow.com/questions/7176908/how-to-get-index-of-object-by-its-property-in-javascript
function findIndexByValue(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}