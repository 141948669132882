/*
	Required:
		https://github.com/useAnimations/react-useanimations 
*/

import { useContext } from 'react';
import UseAnimations from 'react-useanimations';

import './Footer.scss';
import ThemeContext from "../../context/ThemeContext";
import { ReactComponent as ScreenplifyLogo } from '../../assets/screenplify_logo.svg';
import { ReactComponent as ScreensLogo } from '../../assets/screens-full-inverse-color-logo.svg';

import facebook from 'react-useanimations/lib/facebook'
import instagram from 'react-useanimations/lib/instagram'
import twitter from 'react-useanimations/lib/twitter'


const Footer = () => {
	const theme = useContext(ThemeContext);

	const { products, resources, company } = theme.footer;
	

	return (
		<footer className="bg-dark py-5">
			<div className="container">

				<div className="row">
					
					<div className="col-12 col-md-3">
						<a href="//www.screenplify.com" className="py-0 w-50 d-inline-block footer-logo">
							{theme.site === 'Screenplify' ? <ScreenplifyLogo /> : <ScreensLogo />}
						</a>
						<p className="py-2 text-light ls-8 ftw-300">{ theme.description }</p>

						<div className="social-icons justify-content-center justify-content-md-start">
							{
								Object.keys(theme.social.links).map((prop, index) => (
									<a href={theme.social.links[prop]} target="_blank" rel="noopener noreferrer" key={index}>
										{prop === 'facebook' && <UseAnimations animation={ facebook } size={24} wrapperStyle={{ margin: '0 auto', position: 'relative' }} />}
										{prop === 'twitter' && <UseAnimations animation={ twitter } size={24} wrapperStyle={{ margin: '0 auto', position: 'relative' }} />}
										{prop === 'instagram' && <UseAnimations animation={ instagram } size={24} wrapperStyle={{ margin: '0 auto', position: 'relative' }} />}
									</a>
								))
							}
						</div>

					</div>

					<div className="col-12 col-md-3 pl-md-5">
						{
							products.map((item, index) => (
								<a key={ index } href={ item.link } target={ item.target } className="btn btn-link text-center text-md-left">{ item.text }</a>
							))
						}
					</div>

					<div className="col-12 col-md-3 pl-md-5">
						{
							resources.map((item, index) => (
								<a key={ index } href={ item.link } target={ item.target } rel="noopener noreferrer" className="btn btn-link text-center text-md-left">{ item.text }</a>
							))
						}
					</div>

					<div className="col-12 col-md-3">
						{ company === undefined ?
							<div className="footer-address text-center text-md-left pt-3 pt-md-0 ">
								<address>
									<strong>Ounch SDN BHD,</strong><br />
									#06-01, Wisma Minlon
									 <br />Batu 12, Lebuhraya Sg Besi,
									<br /> 43300 Seri Kembangan,
									<br /> Selangor, Malaysia.
								</address>
								<address>
									<strong>Tel:</strong>
									{ theme.social.tel }
								</address>
								<address>
									<strong>Email:</strong>
									{ theme.social.email }
								</address>		
							</div>
							:
							company.map((item, index) => (
								<a key={index} href={item.link} target={item.target} rel="noopener noreferrer" className="btn btn-link text-center text-md-left">{item.text}</a>
							))
						}
					</div>

				</div>

				<hr/>

				<p className="copy m-0 text-center text-uppercase ls-8 ftw-600">
				    <span>Copyright 2014-{ new Date().getFullYear() } ©</span>
				    <span>Ounch Sdn Bhd</span>
				    <span> • { theme.site }</span>
				</p>


			</div>
		</footer>
	)
}

export default Footer;