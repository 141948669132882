import { useReducer } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import ThemeContext from './context/ThemeContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Favicon from 'react-favicon';

import Home from './views/Home';
import JustApp from './views/JustApp';

function App() {

    const theme = window.config.customization[window.location.hostname]; 

    const [state] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), { ...theme});

    // Set Dom Title
    document.title = `Templates • ${theme.site}`;

    return (
        <ThemeContext.Provider value={{ ...state }}>
            <Router basename="/my">
                <Favicon url={ theme.favicon } animated={ true } alertCount={0} />

                <Switch>

                    <Route exact path="/a">
                        <JustApp />
                    </Route>
                    
                    <Route path="/">
                        <Navbar />
                        <Home />
                        <Footer />
                    </Route>

                </Switch>
            </Router>
        </ThemeContext.Provider>
    );
}

export default App;


