
import { useContext, useEffect, useRef } from 'react';

import './index.scss';
import ThemeContext from '../../context/ThemeContext';

import { ReactComponent as Sun } from '../../assets/sun.svg';
import Snowy from '../WeatherIcon/Snowy';
import Clock from '../ClockWidget'

const Hero = () => {

    const theme = useContext(ThemeContext);

    const parentNode = useRef(null);
    const canvasNode = useRef(null);

    useEffect(() => {

        window.addEventListener("resize", handleScale);

        handleScale();

        return () => {
            window.removeEventListener("resize", handleScale);
        }

    }, []);


    const handleScale = () => {
        const parentWidth = parentNode.current.offsetWidth - 30; // 30 for padding
        const canvasWidth = canvasNode.current.offsetWidth;
        const canvasHeight = canvasNode.current.offsetHeight;
        const scale = ((parentWidth * 100) / canvasWidth);
        const height = (canvasHeight * scale) / 100;

        parentNode.current.style.height = `${height}px`;
        canvasNode.current.style.transform = `scale(${scale / 100})`;
    }


    return (
       <div className="section section-hero">
            <div className="container">
                <div className="row py-5">

                    <div className="col-12 col-lg-5 d-flex align-items-center">
                        <div className="pt-5 text-center text-lg-left w-100">
                            <h1 className="ftw-600 m-0" >{ theme.heroTitle }</h1>
                            <p className="lead ftw-400 ls-5 text-muted py-3" dangerouslySetInnerHTML={{ __html: theme.heroDescription} }></p>
                        </div>
                    </div>

                    <div className="col-12 col-lg-7" ref={parentNode}>
                        <div className="hero-animation" ref={canvasNode}>

                            <div className="screens screens-weather">
                                <div className="screens-inner"></div>
                                <div className="animation">
                                    <Sun/>
                                    <Snowy />
                                </div>
                                <Clock/>
                                <div className="arrow"></div>
                            </div>

                            <div className="screens screens-burger">
                                <div className="screens-inner"></div>
                            </div>

                            <div className="screens screens-moode">
                                <div className="screens-inner"></div>
                            </div>

                            <div className="screens screens-video">
                                <div className="screens-inner"></div>
                                <div className="arrow"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
       </div>
    );
}
 
export default Hero;