import { useReducer } from 'react';

import HomeContext from '../context/HomeContext';
import Hero from '../components/Hero';
import FilterBar from '../components/FilterBar';
import Listing from '../components/Listing';
import Subscribe from '../components/Subscribe';
import HomeReducerObject from '../context/HomeReducerObject';

const Home = () => {

    const homeReducerObject = HomeReducerObject;

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), { ...homeReducerObject });

    return (
        <HomeContext.Provider value={{ ...state, setFilter: setState }}>
            <Hero />
            <FilterBar />
            <Listing />
            <Subscribe />
        </HomeContext.Provider>
    );
}
 
export default Home;