import { useEffect, useRef } from "react";


const CardImage = (props) => {
    const element = useRef();
    const defaultSrc = "https://via.placeholder.com/255/FCFCFC/CCCCCC/?text=Loading";

    useEffect(() => {
        const { src } = props;

        const imageLoader = new Image();
            imageLoader.src = src;

        imageLoader.onload = () => {
            element.current.style.backgroundImage = `url(${src})`;
        }
        
    }, [props])

    return (
        <div ref={element} {...props} style={{ backgroundImage: `url(${defaultSrc})` }}></div>
    );
}
 
export default CardImage;