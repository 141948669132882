import { useReducer } from 'react';

import HomeContext from '../context/HomeContext';
import FilterBar from '../components/FilterBar';
import Listing from '../components/Listing';
import HomeReducerObject from '../context/HomeReducerObject';
import { inIframe } from '../components/Helpers';

const JustApp = () => {

    const homeReducerObject = HomeReducerObject;

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), { ...homeReducerObject});


    const handleParentFrame = () => {
        // Handle Background
        if (inIframe()) {
            document.body.style.background = 'transparent';
            document.body.style.overflow = 'hidden';
            document.domain = "screens.my";

            console.log(document.domain)

            try {
                window.parent.__winResize();
            } catch (error) {
                console.log(error)
            }
        }
    }

    // Init
    handleParentFrame();

    return (
        <HomeContext.Provider value={{ ...state, setFilter: setState }}>
            <FilterBar />
            <Listing />
        </HomeContext.Provider>
    );
}

export default JustApp;