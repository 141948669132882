
const urlParams = new URLSearchParams(window.location.search);

const DataObject = {
    string: '',
    orientation: urlParams.has('orientation') ? urlParams.get('orientation') : 'All',
    category: urlParams.has('category') ? urlParams.get('category') : 'All',
    resolution: urlParams.has('resolution') ? urlParams.get('resolution') : 'All',
    size: '',
    distance: '',
    offset: '50',
    page: '1'
}

export default DataObject;