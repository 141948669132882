
import { useRef, useEffect, useContext, useReducer } from 'react';
import axios from 'axios';
import UseAnimations from 'react-useanimations';

import HomeContext from '../../context/HomeContext';
import ThemeContext from '../../context/ThemeContext';

import './index.scss';

import loading from 'react-useanimations/lib/loading'
import { SearchIcon, FilterIcon } from '@primer/octicons-react';
import { UpdateQueryString } from '../Helpers';
import { useHeaderOffset } from '../../hooks/useHeaderOffset';
import MultiSelect from '../MultiSelect';

const FilterBar = () => {

    const theme = useContext(ThemeContext)
    const context = useContext(HomeContext);
    const parentNode = useRef(null);
    const searchTimer = useRef(null);
    const headerBarOffset = useHeaderOffset();

    const { optionsScreenSize, optionsOrientation, optionsResolution } = window.config.filterOptions;

    const [state, setState] = useReducer((state, newState) => ({
        ...state,
        ...newState
    }), {
        isLoading: true,
        isError: false,
        isSearching: false,
        categories: {}
    });

    useEffect(() => {

        window.addEventListener("scroll", handleScroll);

        const getFilterParams = () => {
            let URL = theme.paths.getFilterParams;
                URL = UpdateQueryString(URL, 'site', encodeURIComponent(theme.site.toLowerCase()))
                URL = UpdateQueryString(URL, 'ts', new Date().getTime())

            axios
                .get(URL)
                .then((resp) => {
                    if (resp.data.status === 'true') {

                        setState({
                            isLoading: false,
                            isError: false,
                            categories: resp.data.data.categories
                        });

                    } else {
                        setState({
                            isLoading: false,
                            isError: true,
                            categories: {}
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setState({
                        isLoading: false,
                        isError: true,
                        categories: {}
                    });
                });
        }

        
        getFilterParams();
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [theme]);


    const handleScroll = () => {
        
        if (parentNode.current){
    
            const winOffset = window.scrollY;
            const nodeTop = parentNode.current.offsetTop;
            // Toggle Class
            parentNode.current.classList[(winOffset + (headerBarOffset + 35)) > nodeTop ? 'add' : 'remove']('fixed-nav');
        }
    }


    const onChangeHandler = (prop, data) => {
        const string = data.map(({ value }, index) => value).toString();

        // Set Filter
        let obj = {}
        obj[prop] = string;

        context.setFilter(obj);
    }


    const onSearchHandler = (e) => {
        setState({ isSearching: true });
        
        if(searchTimer.current !== null){
            clearTimeout(searchTimer.current)
            searchTimer.current = null;
        }
        
        searchTimer.current = setTimeout(() => {
            context.setFilter({ string: e.target.value });
            clearTimeout(searchTimer.current)
            searchTimer.current = null;
            setState({ isSearching: false });
        }, 1000);
    }

    return (
        <div ref={parentNode} className="filter-bar">
            <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{top: `${headerBarOffset}px`}}>
                <div className="container">
                    <button className="navbar-toggler ml-auto py-2 px-3" type="button" data-toggle="collapse" data-target="#navbarFilter" aria-controls="navbarFilter" aria-expanded="false" aria-label="Toggle navigation">
                        <FilterIcon size={16} />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarFilter">

                        <ul className="navbar-nav mr-auto">

                            <li className="nav-item dropdown has-megamenu fancy-dropdown">
                                <div className="title">Category</div>
                                <button className="btn as-is dropdown-toggle btn-outline-secondary" data-toggle="dropdown">{context.category}</button>
                                <div className="dropdown-menu megamenu">
                                    {
                                        state.isLoading && <div className="text-center py-5">
                                            <div className="loading-icon impetus s-32"></div>
                                        </div>
                                    }
                                    {
                                        state.isError && <div className="text-center py-5">
                                                <h5 className="ls-8 py-4 text-danger">Oops!</h5>
                                                <p className="text-secondary ls-8">Something went wrong. Try again.</p>
                                            </div>
                                    }
                                    {
                                        Object.keys(state.categories).length && 
                                            <>
                                                <div className="row m-0">
                                                    { Object.keys(state.categories).map((prop, index) => (
                                                    
                                                        <div className="col" key={index}>
                                                            <h6 className="p-3 m-0 border-bottom ftw-600 text-uppercase">{prop}</h6>
                                                            {
                                                                state.categories[prop].map((item, idx) => (
                                                                    <button className="dropdown-item" key={idx} onClick={() => context.setFilter({ category: `${prop} / ${item}` })}>{ item }</button>
                                                                ))
                                                            }
                                                        </div>
                                                    
                                                    )) } 
                                                </div>
                                                <div className="text-center p-2">
                                                    <button className="btn btn-light btn-block text-center text-uppercase ftw-600" onClick={ () => context.setFilter({ category: `All` })}>Clear</button>
                                                </div>
                                            </>    
                                        
                                    }
                                </div>
                            </li>
                            
                            <li className="nav-item dropdown fancy-dropdown">
                                <div className="title">Orientation</div>
                                <button className="btn as-is dropdown-toggle btn-outline-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {context.orientation}
                                </button>
                                <div className="dropdown-menu">
                                    {
                                        optionsOrientation.map((item, index) => (
                                            <button key={index} className="dropdown-item" onClick={() => context.setFilter({ orientation: item.value })} >{ item.label }</button>
                                        ))
                                    }
                                </div>
                            </li>

                            <li className="nav-item dropdown fancy-dropdown">
                                <div className="title">Resolution</div>
                                <button className="btn as-is dropdown-toggle btn-outline-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {context.resolution}
                                </button>
                                <div className="dropdown-menu py-3" style={{ minWidth: '350px' }}>
                                    <div className="row m-0">
                                    {
                                        optionsResolution.map((item, index) => (
                                            <div className="col-4 d-flex mb-3" key={index}>
                                                <button className="btn btn-light btn-block text-center" onClick={() => context.setFilter({ resolution: item.value })} dangerouslySetInnerHTML={{__html: item.label}} ></button>
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                            </li>

                            <MultiSelect
                                title="Screen Size"
                                options={optionsScreenSize}
                                onChange={(e) => onChangeHandler('size', e)} />

                        </ul>

                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <div className="title">Search</div>
                                <div className="input-with-icon">
                                    <input className="form-control" type="search" placeholder="" aria-label="Search" onChange={(e) => onSearchHandler(e) } />
                                    {state.isSearching ? <UseAnimations animation={loading} size={24} className="icon" wrapperStyle={{ margin: '0 auto', position: 'absolute' }} /> : <SearchIcon size={16} /> }
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </div>
    );
}
 
export default FilterBar;